import { template as template_bb917c7b49d34170a281c561f1807138 } from "@ember/template-compiler";
const SidebarSectionMessage = template_bb917c7b49d34170a281c561f1807138(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
