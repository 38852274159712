import { template as template_d1a5dbcc882345299aa30b98b35055d5 } from "@ember/template-compiler";
const FKControlMenuContainer = template_d1a5dbcc882345299aa30b98b35055d5(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
